<template>
<div>
</div>
</template>

<script>
import router from "@/router";
import {mapActions} from "vuex";


export default {
  name: "AdminLogin",
  async mounted() {
    const route = this.$route;
    const query = route.query;
    const {token, role, name} = query;

    if (token && role && name) {
      await this.authenticateAs(query);
    } else {
      router.push({name: 'NotFound'})
    }
  },
  methods: {
    ...mapActions('account', ['authenticateAs']),
  }
}
</script>

<style scoped>

</style>